import React, { ReactNode } from "react"
import { IconButton, Modal as MuiModal, Typography} from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

interface IProps {
    title: string,
    isOpen: boolean,
    onClose: () => any,
    children: ReactNode,
    width?: string | number | ResponsiveStyleValue<string | number>,
    height?: string | number | ResponsiveStyleValue<string | number>
}

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'spaceAround',

    bgcolor: 'background.paper',
    border: 1,
    borderColor: 'divider',
    borderRadius: '5px',
    boxShadow: 24,
    
    maxWidth: '90vw',
    maxHeight: '90vh',
    overflowX: 'hidden',
    overflowY: 'hidden'
};

const innerModalStyle = {
    p: '1.5rem',

    width: '100%',
    height: '100%',
    overflow: 'auto'
}

const headerBox = {
    position: 'sticky',
    top: 1,
    right: 0,
    zIndex: 10,

    width: '100%',
    p: '.4rem 0 .4rem 1.5rem',
    bgcolor: 'background.paper',
    borderBottom: 1,
    borderColor: 'divider',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}

const buttonStyle = {
    ml: '1rem'
};

export class Modal extends React.Component<IProps> {

	render() {
        const size: any = { width: 'min(90vw, 500px)' };
        if (this.props.width) size.width = this.props.width;
        if (this.props.height) size.height = this.props.height;

        return <>
            {this.props.isOpen &&
            <MuiModal
              open={this.props.isOpen}
              onClose={this.props.onClose}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...modalStyle, ...size }}>
                    <Box sx={{ ...headerBox }}>
                        <Typography variant="h5">{this.props.title}</Typography>
                        <IconButton sx={{ ...buttonStyle }} onClick={this.props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ ...innerModalStyle }}>
                        {this.props.children}
                    </Box>
                </Box>
            </MuiModal>}
        </>;
    }
}